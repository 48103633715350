<template>
    <div>
        <b-card>
            <div slot="header">
                <div class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Reports</b></h5>
                </div>
            </div>
            <div>
                <div style="overflow-y: auto; overflow-x: hidden; height: 100%; padding: 20px;">
                    <MClientTable :itemPerPageOptions="itemPerPageOptions"
                                  :itemCount="reportCount"
                                  :tableColumns="reportOptions.columns"
                                  :tableOptions="reportOptions"
                                  :items="reports"
                                  @getItemsByPage="getReportsByPage">
                        <template v-slot:reportNumber="props">
                            <router-link target="_blank" :to="{ name: 'Case ', params: {caseId: props.props.caseId, reportId: props.props._id}}" style="color: inherit">
                                {{props.props.reportNumber}}
                            </router-link>
                        </template>
                        <template v-slot:user="props">
                            <div>{{props.props.assigned_user.name}}</div>
                        </template>
                        <template v-slot:created_date="props">
                            <div>{{new Date(props.props.created_date).toLocaleString()}}</div>
                        </template>
                        <template v-slot:approval_date="props">
                            <div>{{new Date(props.props.approval_date).toLocaleString()}}</div>
                        </template>
                        <template v-slot:h__goToReport class="float-right">
                            <span style="float: right; margin-bottom: 0">Go To Report</span>
                        </template>
                        <template v-slot:goToReport="props">
                            <router-link target="_blank" :to="{ name: 'Case ', params: {caseId: props.props.caseId, reportId: props.props._id}}" style="color: inherit">
                                <b-button style="float: right; color: #20a8d8; background-color: #2a2a2a; width: 29px"
                                          size="sm"
                                          title="Go To Report"
                                          variant="dark">
                                    <fa-icon :icon="['fas', 'share']" style="margin-left: -2px;"/>
                                </b-button>
                            </router-link>
                        </template>
                    </MClientTable>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import Report from "@/services/report";
import MClientTable from "@/components/m-client-table";
export default {
    name: "Reports",
    components: {
        MClientTable
    },
    data() {
        return {
            itemPerPageOptions: [
                {text: 10, value: 10},
                {text: 25, value: 25},
                {text: 50, value: 50},
                {text: 100, value: 100},
            ],
            reportCount: 0,
            reportOptions: {
                columns: ['reportNumber', 'user', 'approval_date', 'created_date', 'goToReport'],
                sortable: ['reportNumber', 'user', 'approval_date', 'created_date'],
                headings: {
                    reportNumber: 'Report Number',
                    user: 'User',
                    approval_date: 'Date Approved',
                    created_date: 'Date Created',
                    goToReport: 'Go To Report'
                },
                alwaysShowPerPageSelect: true,
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {chunk: 5, edge: false, nav: 'scroll'},
                perPage: 25,
                skin: 'table table-striped table-hover',
                reportNumber: function (ascending) {
                    return function (a, b) {
                        let aArray = a.reportNumber.split('-');
                        let bArray = b.reportNumber.split('-');
                        let aReport = aArray[1] + aArray[2];
                        let bReport = bArray[1] + bArray[2];
                        if (parseFloat(aReport) < parseFloat(bReport)) {
                            return ascending === true ? -1 : 1;
                        } else if (parseFloat(aReport) > parseFloat(bReport)) {
                            return ascending === true ? 1 : -1;
                        }
                        return 0;
                    }
                },
                created_date: function (ascending) {
                    return function (a, b) {
                        if (new Date(a.created_date).valueOf() < new Date(b.created_date).valueOf()) {
                            return ascending === true ? -1 : 1;
                        } else if (new Date(a.created_date).valueOf() > new Date(b.created_date).valueOf()) {
                            return ascending === true ? 1 : -1;
                        }
                        return 0;
                    }
                },
                approval_date: function (ascending) {
                    return function (a, b) {
                        if (new Date(a.approval_date).valueOf() < new Date(b.approval_date).valueOf()) {
                            return ascending === true ? -1 : 1;
                        } else if (new Date(a.approval_date).valueOf() > new Date(b.approval_date).valueOf()) {
                            return ascending === true ? 1 : -1;
                        }
                        return 0;
                    }
                }
            },
            reports: [],
        }
    },
    async created() {
        await this.getReportsByPage({
            page: 1,
            count: 25,
            text: '',
            ascending: {
                column: 'reportNumber', ascending: true
            },
            restrict: false
        });
    },
    methods: {
        getReportsByPage(data) {
            Report.getReportsByPage({
                page: data.page,
                count: data.count,
                text: data.text,
                ascending: data.ascending,
                userType: 'records'
            }).then(response => {
                this.reports = response.data.results;
                this.reportCount = response.data.total;
            });
        },
    }
}
</script>

<style scoped>

</style>